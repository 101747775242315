import { Priority } from '@app//constants/enums/priorities';
import { EntityData } from '@app/api/master/entity.api';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { Tag } from '@app/components/common/Tag/Tag';
import { categoriesList } from '@app/constants/categoriesList';
import { TransactionType } from '@app/constants/transaction';
import { CurrencyType } from '@app/interfaces/interfaces';
import { Tag as AntdTag, Col, Row } from 'antd';
import Cookies from 'js-cookie';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useAtomValue } from 'jotai';
import { StatementListAtom } from '@app/components/layouts/main/MainLayout/MainLayout';
dayjs.extend(timezone);
dayjs.extend(utc);

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const capitalizeEachWord = (string: string): string => {
  return string
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const getCategoryTitleByName = (name: string): string => {
  const category = categoriesList.find((category) => category.name === name);
  return category ? category.title : '';
};

export const formatRupiahPrice = (price: string | number) => {
  const constPrice = typeof price === 'number' ? price : parseInt(price);
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(constPrice);
};

export const roundingPriceUp = (number: number, precision: number): number => {
  const factor = Math.pow(10, precision);
  return Math.ceil(number / factor) * factor;
};

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyType,
  isIcon = true,
): string | React.ReactNode => {
  switch (currency) {
    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const getStatusLabelTag = (text: string | null, style?: React.CSSProperties) => {
  if (!text) return null;

  const statusMap: {
    [key: string]: { color: 'orange' | 'success' | 'default' | 'error' | 'blue' | 'purple'; title: string };
  } = {
    DRAFT: { color: 'default', title: 'Draft' },
    IN_PROGRESS: { color: 'blue', title: 'In Progress' },
    PROCESSED: { color: 'blue', title: 'Processed' },
    COMPLETED: { color: 'success', title: 'Completed' },
    COMPLETE: { color: 'success', title: 'Complete' },
    CANCELLED: { color: 'error', title: 'Cancelled' },
    WAITING_FOR_PAYMENT: { color: 'orange', title: 'Waiting for Payment' },
    WAITING_PAYMENT_TO_VENDOR: { color: 'purple', title: 'Waiting for Payment to Vendor' },
  };

  const { color, title } = statusMap[text] || { color: 'default', title: text };

  return (
    <AntdTag style={style} color={color}>
      {title}
    </AntdTag>
  );
};

export const getPaymentOutstandingTag = (outstanding: number) => {
  const textColor = 'white';
  let bgColor: 'warning' | 'success' | 'primary' | 'error' | 'secondary' = 'primary';
  let title = '';
  if (outstanding > 0) {
    bgColor = 'error';
    title = 'Belum Lunas';
  } else {
    bgColor = 'success';
    title = 'Lunas';
  }
  return <Tag title={title} bgColor={bgColor} color={textColor} />;
};

export const getStatusAction = (
  status: string,
  entity: string,
): Array<{
  label: string;
  action: TransactionType;
}> => {
  const cancel = {
    label: 'Cancel',
    action: TransactionType.CANCELLED,
  };

  if (status === 'DRAFT') {
    return [
      {
        label: 'Processed',
        action: TransactionType.PROCESSED,
      },
      cancel,
    ];
  } else if (status === 'IN_PROGRESS') {
    return [
      {
        label: entity == 'tt_quotation' ? 'Completed' : 'Waiting for Payment',
        action: entity == 'tt_quotation' ? TransactionType.COMPLETED : TransactionType.WAITING_FOR_PAYMENT,
      },
      cancel,
    ];
  } else if (status === 'PROCESSED') {
    return [
      {
        label: 'In Progress',
        action: TransactionType.IN_PROGRESS,
      },
      cancel,
    ];
  } else if (status === 'WAITING_FOR_PAYMENT') {
    return [
      {
        label: 'Waiting Payment to Vendor',
        action: TransactionType.WAITING_PAYMENT_TO_VENDOR,
      },
    ];
  } else if (status === 'WAITING_PAYMENT_TO_VENDOR') {
    return [
      {
        label: 'Completed',
        action: TransactionType.COMPLETED,
      },
    ];
  } else if (status === 'COMPLETED') {
    return [];
  } else if (status === 'CANCELLED') {
    return [];
  }
  return [];
};

export const getUserBranch = (): EntityData | null | undefined => {
  const branches = Cookies.get('branch');

  if (!branches) return null;

  const dataBranches = JSON.parse(branches) as Array<EntityData>;

  return dataBranches.find((branch) => {
    return branch.selected;
  });
};

export const getUserDetail = (): EntityData | null | undefined => {
  const user = Cookies.get('user_logged');
  if (!user) return null;
  return JSON.parse(user) as Array<EntityData>;
};

export const checkPermission = (entity: string, action: 'READ' | 'CREATE' | 'UPDATE' | 'DELETE'): boolean => {
  const user = getUserDetail();
  if (user && user.root) return true;

  if (user && user.is_root) return true;

  const permission = Cookies.get(entity);
  if (!permission) return false;

  const permissionData = JSON.parse(permission);
  if (!permissionData) return false;

  switch (action) {
    case 'READ':
      return permissionData.grant_read;
    case 'CREATE':
      return permissionData.grant_create;
    case 'UPDATE':
      return permissionData.grant_update;
    case 'DELETE':
      return permissionData.grant_delete;
    default:
      return false;
  }
};

export const checkPagePermission = (
  permissions: {
    entity: string;
    action: 'READ' | 'CREATE' | 'UPDATE' | 'DELETE';
  }[],
): boolean => {
  const user = getUserDetail();
  if (user && user.root) return true;

  if (user && user.is_root) return true;

  let isPermitted = true;
  permissions.forEach((permission) => {
    const data: string = Cookies.get(permission.entity) || '';

    if (data == '') isPermitted = false;
    if (data != '') {
      const permissionData = JSON.parse(data);

      if (!permissionData) isPermitted = false;
      if (permissionData) {
        switch (permission.action) {
          case 'READ':
            isPermitted = isPermitted && permissionData.grant_read;
            break;
          case 'CREATE':
            isPermitted = isPermitted && permissionData.grant_create;
            break;
          case 'UPDATE':
            isPermitted = isPermitted && permissionData.grant_update;
            break;
          case 'DELETE':
            isPermitted = isPermitted && permissionData.grant_delete;
            break;
          default:
            break;
        }
      }
    }
  });

  return isPermitted;
};

export const formatFullDate = (date: string, format = 'DD MMM YYYY HH:mm:ss') => {
  return dayjs.utc(date).tz(dayjs.tz.guess()).format(format);
};

export const formatDate = (date: string, format = 'DD MMMM YYYY') => {
  return dayjs.utc(date).tz(dayjs.tz.guess()).format(format);
};

export const formatDateToString = (date: Date, locale = 'id-ID'): string => {
  return Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(date);
};
