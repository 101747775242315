import React, { useEffect, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useLoading } from '@app/hooks/useLoading';
import { GlobalLoading } from '@app/components/globalLoading';
import Cookies from 'js-cookie';
import { EntityData, getStatementList } from '@app/api/master/entity.api';

export const StatementListAtom = atom<EntityData | null>(null);

const MainLayout: React.FC = () => {
  const [isLoading, _] = useAtom(useLoading);
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  const navigate = useNavigate();

  const setStatementAtom = useSetAtom(StatementListAtom);

  useEffect(() => {
    if (!Cookies.get('token')) {
      navigate('/auth/login');
      console.info('Token is expired');
      return;
    }

    getStatementList().then((res) => {
      const statement: {
        [field: string]: any;
      } = {};

      res.map((st: any) => {
        statement[st.entity_name] = {
          grant_read: st.grant_read || statement[st.entity_name].grant_read,
          grant_create: st.grant_create || statement[st.entity_name].grant_create,
          grant_update: st.grant_update || statement[st.entity_name].grant_update,
          grant_delete: st.grant_delete || statement[st.entity_name].grant_delete,
        };

        Cookies.set(st.entity_name, JSON.stringify(statement[st.entity_name]));
      });

      setStatementAtom(statement);
    });
  }, []);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
      {isLoading.isLoading && <GlobalLoading message={isLoading.message} />}
    </S.LayoutMaster>
  );
};

export default MainLayout;
